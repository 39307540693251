import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Faq, KoboDetails, PageTitle } from "@components"

const HowItWorks = () => (
  <Layout>
    <Seo title="How it works" />
    <PageTitle title="How it works" />
    <KoboDetails />
    <Faq />
  </Layout>
)

export default HowItWorks;
